import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Entry from "../pages/entry";
import Customers from "../pages/customers";
import Companies from "../pages/companies";
import Logs from "../pages/logs";
import Permissions from "../pages/permissions";
import Roles from "../pages/roles";
import Users from "../pages/users";
import Profile from "../pages/profile";
import error404 from "../pages/error404";
import Attendance from "../pages/attendance";
import Forms from "../pages/forms";
import SideBar from "../components/navigation/sideBar";
import Header from "../components/navigation/header";
import Perform from "../pages/perform";
import Settings from "../pages/settings";
import Actions from "../pages/actions";
import Dashboard from "../pages/dashboard";
import Assigned from '../pages/assigned';
import SOS from '../pages/sos';
import { Dimmer, Loader } from "semantic-ui-react";
import { checkSession } from "../actions";

class App extends Component {
  state = { sessionChecking: true };

  componentWillMount() {
    this.setState({ sessionChecking: true }, () =>
      this.props
        .checkSession()
        .then(x => this.setState({ sessionChecking: false }))
    );
  }

  renderPage() {
    if (this.props.logged === false) {
      return <Entry />;
    } else {
      return (
        <BrowserRouter>
          <SideBar>
            <Header username={this.props.logged} />
            {/* <NavBar> */}
            <Switch>
              <Route
                activeClassName="active"
                exact
                path="/"
                component={Dashboard}
              />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/customers" component={Customers} />
              <Route exact path="/companies" component={Companies} />
              <Route exact path="/permissions" component={Permissions} />
              <Route exact path="/roles" component={Roles} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/logs" component={Logs} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/attendance" component={Attendance} />
              <Route exact path="/forms" component={Forms} />
              <Route exact path='/perform' component={Perform} />
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/actions" component={Actions} />
              <Route exact path="/assinged" component={Assigned} />
              <Route exact path="/sos" component={SOS} />
              <Route exact component={error404} />
            </Switch>
            {/* </NavBar> */}
          </SideBar>
        </BrowserRouter>
      );
    }
  }

  render() {
    return this.state.sessionChecking ? (
      <Dimmer active inverted>
        <Loader />
      </Dimmer>
    ) : (
        this.renderPage()
      );
  }
}

const mstp = state => {
  // console.log(state)
  return {
    logged: state.auth.logged,
    userName: state.auth.userBar
  };
};

export default connect(mstp, { checkSession })(App);

import React, { Component } from "react";
import {
  Button,
  Grid,
  Icon,
  Label,
  Popup,
  Header,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import LoaderView from "../components/loaderView";
import UnauthorizedView from "../components/unauthorizedView";
import DataTable from "../components/dataTable";
import CallerWindow from "./callerWindow";
import * as actions from "../actions";
import CaseFilterForm from "../components/forms/myCaseFilter";

class Assigned extends Component {
  state = {
    filterShow: false,
    loading: false,
    callerWindow: false,
    pageSize: 0,
    currentPage: 1,
    loadTable: false,
    triggerUpdate: 1,
    loadTable: false,
    currentCalleeId: null,
    nextCalleeId: null,
    loadingNext: false,
    triggerUpdate: 0,
    currentActionId: null,
    loadingPrev: null,
    prevCalleeId: null,
    searchParameter: {},
  };

  componentDidMount = () => {
    // if (this.props.performed) {
    // this.props.LoadAllUsersWithID();
    this.props.LoadActiveTags();
    this.setState({ triggerUpdate: this.state.triggerUpdate + 1 });
    this.props.UnloadForm();
    // }
  };

  tablePaging = (pageSize, currentPage) => {
    window.localStorage.setItem("assignedCallingListPageSize", pageSize + "");
    this.setState({ loadTable: true, currentPage: currentPage });
    this.props
      .LoadAllAssignedCallees(
        pageSize,
        currentPage,
        false,
        this.state.searchParameter
      )
      .then((x) =>
        this.setState({
          loaded: true,
          access: true,
          loadTable: false,
          displayAction: false,
        })
      )
      .catch((x) => this.setState({ loaded: true, access: false }));
  };

  setNextCallee = (defaulterId) => {
    let data = this.props.data;
    let index = data.findIndex((item) => item.defaulterId === defaulterId);
    if (index === data.length - 1) {
      this.setState({ nextCalleeId: null });
      return;
    }
    if (index !== null && index !== data.length - 1) {
      this.setState({ nextCalleeId: data[index + 1] });
    }
  };

  setPrevCallee = (defaulterId) => {
    let data = this.props.data;
    let index = data.findIndex((item) => item.defaulterId === defaulterId);
    if (index === 0) {
      this.setState({ prevCalleeId: null });
      return;
    }
    if (index !== null && index !== 0) {
      this.setState({ prevCalleeId: data[index - 1] });
    }
  };

  getNextCallee = (value) => {
    let { defaulterId, id } = value;
    console.log(defaulterId);
    console.log(id);
    this.setState({ loadingNext: true });
    this.props
      .LoadCalleeDetails(defaulterId)
      .then(() => {
        this.props.LoadPhoneNumbers(defaulterId);
        this.setState({
          currentCalleeId: value.defaulterId,
          currentActionId: id,
          loadingNext: false,
        });
        this.setNextCallee(value.defaulterId);
        this.setPrevCallee(value.defaulterId);
      })
      .then(() => this.setState({ loadTable: false, loadingNext: false }));
  };

  getPrevCallee = (value) => {
    let { defaulterId, id } = value;
    console.log(defaulterId);
    console.log(id);
    this.setState({ loadingPrev: true });
    this.props
      .LoadCalleeDetails(defaulterId)
      .then(() => {
        this.props.LoadPhoneNumbers(defaulterId);
        this.setState({
          currentCalleeId: value.defaulterId,
          currentActionId: id,
          loadingPrev: false,
        });
        this.setPrevCallee(value.defaulterId);
        this.setNextCallee(value.defaulterId);
      })
      .then(() => this.setState({ loadTable: false, loadingPrev: false }));
  };

  actionButtons = (value) => {
    // console.log(value)
    return (
      <Popup
        content={`Perform Action on ${value.name}`}
        position="top right"
        on="hover"
        trigger={
          <Button
            size="mini"
            color="blue"
            icon="call"
            disabled={false}
            onClick={() => {
              this.setState({ loadTable: true });
              this.setNextCallee(value.defaulterId);
              this.setPrevCallee(value.defaulterId);
              this.props
                .LoadCalleeDetails(value.defaulterId)
                .then(() =>
                  this.setState({
                    loadTable: false,
                    callerWindow: true,
                    currentCalleeId: value.defaulterId,
                    currentActionId: value.id,
                  })
                )
                .then(() => this.setState({ loadTable: false }));
              this.props.LoadFormByID(value.formId);
            }}
          />
        }
      />
    );
  };

  renderTable = () => {
    const data = this.props.data;
    //console.log(data);

    return (
      <>
        <DataTable
          header={[
            {
              name: "Date",
              accessor: "date",
            },
            {
              name: "Name",
              accessor: "defaulterName",
            },
            {
              name: "Tracking Id",
              accessor: "companyTrackingId",
            },
            {
              name: "Cycle Day",
              accessor: "cycleDay",
            },
            {
              name: "Area",
              accessor: "area",
            },
            {
              name: "Balance",
              accessor: "balance",
            },
            {
              name: "Company",
              accessor: "companyName",
            },
            {
              name: "Contact",
              accessor: "contact",
              separator: ",",
            },
            {
              name: "Assgined By",
              accessor: "assignedByUserName",
            },
            {
              name: "Priority",
              accessor: "priority",
            },
          ]}
          actions={(x) => this.actionButtons(x)}
          loading={this.state.loadTable}
          // show={this.state.access && this.state.loaded && !this.state.displayCustomer}
          rowCount={this.props.total}
          show={true}
          data={data}
          pageSizes={[10, 20, 50, 100, 250]}
          selectedPageSize={window.localStorage.getItem("assignedCallingListPageSize")}
          trigger={this.state.triggerUpdate}
          // noPagination
          onPageChange={(x, y) => this.tablePaging(x, y)}
          overflowX
        />
      </>
    );
  };

  onstructForm = (id) => {
    this.props
      .LoadFormByID(id)
      .then((x) => {
        /*console.log(this.props.loadedForm)*/
      }, this.setState({ triggerUpdate: this.state.triggerUpdate }))
      .catch((x) => console.log("error"));
  };

  submitFillForm = (values) => {
    console.log("values;", values);
    this.setState({ submitLoading: true });
    var data = {};
    data.actionid = this.state.currentActionId;
    data.calledPhoneNumber = values["calledPhoneNumber"];
    var arr = [];
    var obj = {};
    Object.keys(values).forEach((key) => {
      var newData = {};
      if (key.includes("FORM")) {
        var k = key.substring(5);
        newData.caption = k;
        newData.value = values[key];
        arr.push(newData);
      }
      if (key.includes(values.resultId + "_")) {
        let rId = values.resultId + "";
        var k = key.substring(rId.length + 1);
        if (typeof values[key] === "object")
          obj[k] = values[key].toLocaleDateString();
        else obj[k] = values[key];
        console.log(k);
      }
    });
    data.formValues = arr;
    let tag = "";
    this.props.tagsList.forEach((x) => {
      if (x.value === values.resultId) {
        console.log("entered inside");
        tag = x.text;
      }
    });
    data[tag] = obj;
    data.resultId = values.resultId;
    console.log(data);
    var dataSend = {};
    dataSend.outcome = data;
    if (values.paymentimages !== undefined && values.paymentimages !== null) {
      dataSend.paymentimages = Object.values(values.paymentimages);
    }
    if (
      values.supportingImages !== undefined &&
      values.supportingImages !== null
    ) {
      dataSend.supportingImages = Object.values(values.supportingImages);
    }
    console.log(dataSend);
    let getNext = false;
    let { nextCalleeId, prevCalleeId } = this.state;
    this.props
      .ActionFill(dataSend)
      .then((x) => {
        if (nextCalleeId !== null) {
          this.getNextCallee(this.state.nextCalleeId);
          this.setPrevCallee(this.state.currentCalleeId);
          this.setState({ submitLoading: false });
        } else {
          this.setState({ submitLoading: false, callerWindow: false });
        }
        alert("Form Submitted");
      })
      .catch((x) => {
        this.setState({ submitLoading: false });
        alert("Failed");
      });
  };

  onClickBack = () => {
    this.setState({ callerWindow: false });
  };

  onClickDial = () => {
    return (
      <CallerWindow
        callee={this.props.selectedCallee}
        loadedForm={this.props.loadedForm}
        onBack={this.onClickBack}
        tagsList={this.props.tagsList}
        onSubmitForm={this.submitFillForm}
        prev
        loadingNext={this.state.loadingNext}
        loadingPrev={this.state.loadingPrev}
        onClickPrev={() => this.getPrevCallee(this.state.prevCalleeId)}
        onClickNext={() => this.getNextCallee(this.state.nextCalleeId)}
        userbyID={this.props.userbyID}
        prevCallee={this.state.prevCalleeId}
        nextCallee={this.state.nextCalleeId}
        submitLoading={this.state.submitLoading}
      />
    );
  };

  renderFilterBox = () => {
    // let { datefrom, dateto } = this.state.searchParameter;
    // if (datefrom === undefined && dateto === undefined) {
    //   this.setState({
    //     searchParameter: {
    //       datefrom: new Date(),
    //       dateto: new Date(),
    //     },
    //   });
    // }
    return this.state.filterShow ? (
      <div style={{ marginBottom: "10px" }}>
        <Header attached="top" size="large">
          Filter View All
        </Header>
        <Segment attached>
          <CaseFilterForm
            clearFilters={() => {
              this.setState({ searchParameter: {}, currentPage: 1 }, () =>
                this.setState({ triggerUpdate: this.state.triggerUpdate + 1 })
              );
            }}
            onSubmit={(values) => {
              console.log(values);
              let { priority, region, Addresstype, datefrom, dateto, companyTrackingId } = values;
              let dataObject = {};
              if (priority) dataObject.priority = priority;
              if (Addresstype) dataObject.Addresstype = Addresstype;
              if (region) dataObject.region = region;
              // if (datefrom === undefined) {
              //   datefrom = new Date();
              // }
              if (datefrom === null) {
              } else if (typeof datefrom === "object") {
                dataObject.datefrom = datefrom.toLocaleDateString();
              }
              // if (dateto === undefined) {
              //   dateto = new Date();
              // }
              if (dateto === null) {
              } else if (typeof dateto === "object") {
                dataObject.dateto = dateto.toLocaleDateString();
              }
              dataObject.companyTrackingId = companyTrackingId;
              console.log(dataObject);

              this.setState(
                { searchParameter: dataObject, currentPage: 1 },
                () =>
                  this.setState({ triggerUpdate: this.state.triggerUpdate + 1 })
              );
            }}
            initialValues={this.state.searchParameter}
          />
        </Segment>
      </div>
    ) : null;
  };
  renderFilterButton = () => {
    return (
      <div style={{ marginBottom: "10px" }}>
        <Button
          size="tiny"
          primary={!this.state.filterShow}
          onClick={() => {
            this.setState({ filterShow: !this.state.filterShow });
          }}
        >
          {!this.state.filterShow ? "Search Actions" : "Hide Filter Box"}
        </Button>
      </div>
    );
  };

  renderTableBox = () => {
    return (
      <>
        {this.renderFilterButton()}
        {this.renderFilterBox()}
        {this.renderTable()}
      </>
    );
  };

  render() {
    console.log(this.state);
    return (
      <div>
        {this.state.callerWindow ? this.onClickDial() : this.renderTableBox()}
      </div>
    );
  }
}

const styles = {
  mainDiv: {
    marginLeft: "3vw",
    marginRight: "3vw",
  },
  topBox: {
    marginBottom: "10px",
  },
};

const mstp = (state) => {
  return {
    data: state.teleCaller.data,
    total: state.teleCaller.assignedTotal,
    selectedCallee: state.teleCaller.selectedCallee,
    userbyID: state.user.userbyID,
    loadedForm: state.myForms.loadedForm,
    tagsList: state.tags.activeTags,
  };
};

export default connect(mstp, actions)(Assigned);

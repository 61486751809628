import axios from 'axios';
import {
    LOAD_CUSTOMERS,
    LOAD_DEFAULTER,
    LOAD_TAG_KEYS,
    TOGGLE_DEFAULTER_FLAG,
    LOAD_DROPDOWNS,
    LOAD_USER_DEFAULTER_CODE,
    LOAD_ALL_CODES,
    LOAD_SELECTED_DEFAULTERS,
    LOAD_ACTION_HISTORY
} from './types';
import { logOut } from '.';
import { Form } from 'semantic-ui-react';

const apiURL = require('../config.json').apiURL;

export const LoadCustomers = (pagesize, pagenum, searchParameters, value, status) => {
    var sortedby = {
        param: value,
        isdescending: status
    }
    // console.log(sortedby)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'defaulter/getfiltered/search',
                params: {
                    pagesize,
                    pagenum,
                    parameters: JSON.stringify(searchParameters),
                    sortby: sortedby
                },
                // sortedby: JSON.stringyfy(sortby)
            }).then(({ data }) => {
                // console.log(data)
                data.defaulters.map(x => {
                    // console.log(x)
                    x.otherDetails.forEach(y => {
                        if (y.key.toLowerCase() === 'mru') {
                            x.mru = y.value
                        }
                    })
                })
                // console.log(data)
                dispatch({
                    type: LOAD_CUSTOMERS,
                    payload: {
                        data: data.defaulters,
                        total: data.total
                    }
                })
                resolve('done')
            })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 403) {
                            reject('failed')
                        }
                    } else {
                        dispatch(logOut());
                    }
                });
        })

    }
}

export const DropdownCustomerFilter = (field, options = {}) => {
    // const form = new FormData();

    options.city =  options.city?.toString();

    let url  = '' 
    if(options.city?.length > 0  && options.isRevoked === true){
        options.city = options.city.split('|')[0]
        url = `defaulter/getfilterdropdown/${field}?city=${options.city}&isRevoked=${options.isRevoked}`
    }
    else if( options.city?.length > 0 && options.isRevoked === false){
        options.city = options.city.split('|')[0]
        url = `defaulter/getfilterdropdown/${field}?city=${options.city}`
    } else if((options?.city == null || options.city?.length == 0) && options.isRevoked == true ){
        url = `defaulter/getfilterdropdown/${field}?isRevoked=${options.isRevoked}`
    }else{
        url = `defaulter/getfilterdropdown/${field}`
    }
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + url,
                // data: form
            }).then(({ data }) => {
                var list = {};

                dispatch({
                    type: LOAD_DROPDOWNS,
                    payload: {}
                })

                list.field = field
                list.data = data.dropdown.map(x => {
                    return (
                        {
                            value: x,
                            text: x
                        }
                    )
                })
                let dataList = list.data
                var valueArr = dataList.map(function(item){ return item.value });
                if(field === 'region'){
                    var uniqeArr = valueArr.map(function(item, index){ 
                    
                        if(valueArr.indexOf(item) != index) {
                            console.log('item=<<<>>> if' , item , index)
                            list.data[14].value = item + '|' + index 
                        }else{
                            console.log('item=<<<>>> else' , item , index)
                        }
                        // return item.value 
                    });
                }
                dispatch({
                    type: LOAD_DROPDOWNS,
                    payload: list
                })
                // const newData = data.map(x => {
                //     return {
                //         value: x.,
                //         text: x.name
                //     }
                // })
                // dispatch(reset('Redo'))
                resolve('done')
            })
                .catch((error) => {
                    reject('failed')
                });
        })
    }
}

export const UpdateMultipleFlag = (values) => {
    let form = new FormData();
    Object.keys(values).forEach(key => {
        console.log(key, typeof values[key])
        if (Array.isArray(values[key])) {
            values[key].forEach(value => form.append(key, value));
        } else if (typeof values[key] === 'object' && values[key] !== null) {
            form.append(key, JSON.stringify(values[key]))
        } else {
            form.append(key, values[key]);
        }
    });
    
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: apiURL + 'defaulter/flag',
                data: form
            }).then((response) => {
                console.log(response)
                resolve('done')
            }).catch((error) => {
                console.log(error)
                if (error.response && error.response.status === 403) {

                } else {

                }
                reject('failed')
            });
        })
    }
}

export const UpdateFlag = (id, flag) => {
    const form = new FormData();
    form.append('id', id)
    form.append('flag', flag)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: apiURL + 'defaulter/updateflag',
                data: form
            }).then((response) => {
                console.log(response)
                resolve('done')
            }).catch((error) => {
                console.log(error)
                if (error.response && error.response.status === 403) {
                    reject('failed')
                } else {

                }
            });
        })
    }
}

export const DownloadDefaulterReport = (parameters) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            var form = new FormData();
            form.append('parameters', parameters);
            //form.append('filter', filter);
            axios({
                method: 'post',
                url: apiURL + 'reports/GetDefaulterReportPost',
                data: form,
                responseType: 'blob'
            }).then((response) => {
                let filename = response.headers["content-disposition"].split("filename=")[1];
                filename = filename.substring(0, filename.indexOf(";"));
                //replace by using regex
                //^ = starts with
                //| = or
                //$ = ends with
                //g = global, guess it means parse the entire string
                filename = filename.replace(/^_|_$/g, '');
                
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
                    window.navigator.msSaveOrOpenBlob(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}),
                        filename);
                } else {
                    const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                }
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            });
        })
    }
}

export const DeleteDefaulter = (id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('id', id)
            axios({
                method: 'delete',
                url: apiURL + 'defaulter/delete',
                data: data
            }).then((x) => {
                resolve('done')
            }).catch((x) => {
                reject('failed')
            })
        })
    }
}


export const DeleteDynamicFilter = (value) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            console.log( 'value in action=> ', value)
            const data = new FormData();
            data.append('value', value)
            axios({
                method: 'delete',
                url: apiURL + 'defaulter/DeleteDynamicFilter',
                data: data
            }).then((x) => {
                resolve('done')
            }).catch((x) => {
                reject('failed')
            })
        })
    }
}


export const LoadDefaulter = (id) => {
    // const form = new FormData();
    // form.append('id',id)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'defaulter/get/' + id
            }).then(({ data }) => {
                dispatch({
                    type: LOAD_DEFAULTER,
                    payload: data
                })
                resolve('done')
            }).catch((error) => {
                if (error.response.status === 403) {
                    reject('failed')
                } else {
                    dispatch(logOut())
                }
            });
        })
    }
}

export const LoadTagKeys = (id) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            if (id == "") {
                console.log("it is null")
                dispatch({
                    type: LOAD_TAG_KEYS,
                    payload: []
                })
            } else {
                axios({
                    method: 'get',
                    url: apiURL + 'defaulter/getOtherDetailsKeys/' + id
                }).then(({ data }) => {
                    const newData = data.keys.map(x => {
                        return {
                            value: x,
                            text: x
                        }
                    })
                    dispatch({
                        type: LOAD_TAG_KEYS,
                        payload: newData
                    })
                    resolve('done')
                }).catch((error) => {
                    //  if (error.response.status === 403) {
                    reject('failed')
                    //   } else {
                    //        // dispatch(logOut())
                    //    }
                });
            }
        })

    }
}

const EditCustomerValues = (values, changeit) => {
    return new Promise((resolve, reject) => {
        if (changeit) {
            const newValues = { ...values }
            //  delete newValues
            const form = new FormData();
            Object.keys(newValues).map((key, index) => {
                form.append(key.toLowerCase(), newValues[key])
                return null;
            });
            axios({
                method: 'put',
                url: apiURL + 'defaulter/put',
                data: form
            }).then(({ data }) => {
                resolve('done')
            }).catch(error => {
                if (error.resposne.status === 403) {
                    reject('failed')
                }
            })
        } else {
            resolve('done')
        }
    })
}

export const EditCustomer = (values, changit, pageSize, pageNumber) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            EditCustomerValues(values, changit)
                .then(x => {

                })
            axios({
                method: 'post',
                url: apiURL + 'defaulter/'
            })
        })
    }
}


export const UploadFile = (fileToUpload) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const File = new FormData()
            console.log(fileToUpload)
            File.append('file', fileToUpload, fileToUpload.name)
            axios({
                method: 'post',
                url: apiURL + 'defaulter/uploadexcelfile',
                data: File
            }).then((response) => {
                console.log(response.data)
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const UploadRevoked = (fileToUpload) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const File = new FormData()
            File.append('file', fileToUpload, fileToUpload.name)
            axios({
                method: 'post',
                url: apiURL + 'defaulter/uploadrevoked',
                data: File
            }).then((response) => {
                console.log('Done')
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const UploadSelectCustomerFile = (file) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const File = new FormData()
            File.append('file', file, file.name)
            axios({
                method: 'post',
                url: apiURL + 'defaulter/select',
                data: File
            }).then(({ data }) => {
                console.log(data)
                dispatch({
                    type: LOAD_SELECTED_DEFAULTERS,
                    payload: data
                })
                console.log('Done')
                resolve('done')
            }).catch((error) => {
                console.log('error')
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const UploadUnrevokedCutomers = (file) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            console.log(file)
            let File = new FormData()
            File.append('file', file, file.name)
            axios({
                method: 'put',
                url: apiURL + 'defaulter/uploadUnrevoked',
                data: File
            }).then((response) => {
                console.log('Done')
                console.log('unrevoked')
                console.log(response)
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const UploadPDFCustomer = (files) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            const File = new FormData()
            console.log(files)
            Object.keys(files).map(key => {
                console.log(files[key])
                File.append('files', files[key], files[key].name)
            })
            axios({
                method: 'post',
                url: apiURL + 'defaulter/UploadSupportingDocsMultiple',
                data: File
            }).then((response) => {
                console.log('Done')
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const DownloadSupportingSingleDocument = (path) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            console.log(path)
            axios({
                method: 'get',
                url: apiURL + 'file/getdocument/' + JSON.stringify(path),
                responseType: 'blob',
            }).then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SupportingDocument');
                document.body.appendChild(link);
                link.click();
                link.remove();
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const DownloadSupportingDocumentsZip = (paths) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'file/getMultipleDocsInZip/' + JSON.stringify(paths),
                responseType: 'blob',
            }).then((response) => {
                console.log(response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'SupportingDocs.zip');
                document.body.appendChild(link);
                link.click();
                link.remove();
                resolve('done')
                // console.log(response)
                // resolve('done');
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}
export const LoadElevationImage = (add) => {
    //  console.log(add)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            const Path = new FormData();
            Path.append('path', add)
            axios({
                method: 'post',
                url: apiURL + 'defaulteraddress/getelevationimage',
                data: Path
            }).then((response) => {
                //      console.log(response.data.image)
                var image = new Image();
                image.src = "data:image/png;base64," + response.data.image
                var w = window.open("");
                w.document.write(image.outerHTML)
                resolve('done')
            }).catch((error) => {
                /*if (error.response.status === 403) {
                    reject('failed')
                } else {
                    // dispatch(logOut())
                } */
                reject('failed')
            });
        })
    }
}


export const LoadUserDefaulterCodes = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'userdefaultercode/getcodes/false'
            }).then(({ data }) => {
                console.log("BEING CALLED")
                const CodeArray = data.defaulterCodes.map(x => { return { value: x.id, text: x.code } })
                console.log(CodeArray)
                dispatch({
                    type: LOAD_USER_DEFAULTER_CODE,
                    payload: CodeArray
                })
                console.log('Done')
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const LoadAllCodes = () => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'userdefaultercode/getcodes/true'
            }).then(({ data }) => {
                const CodeArray = data.defaulterCodes.map(x => { return { value: x.code, text: x.code } })
                console.log(CodeArray)
                dispatch({
                    type: LOAD_ALL_CODES,
                    payload: CodeArray
                })
                console.log('Done')
                resolve('done')
            }).catch((error) => {
                console.log(error)
                reject('failed')
            })
        })
    }
}

export const GetActionHistory = (defaulterId) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: apiURL + 'action/getHistory/' + defaulterId
            }).then(({ data }) => {
                console.log(data)
                dispatch({
                    type: LOAD_ACTION_HISTORY,
                    payload: data
                })
                resolve('done')
            }).catch((error) => {
                console.log(error)
                //   if (error.response.status === 403) {
                reject('failed')
                //  } else {
                dispatch(logOut())
                //   }
            })
        })
    }
}

export const RemoveDuplicatePhoneNums = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: apiURL + 'phonenumber/RemoveDuplicateNums',
            }).then((x) => {
                resolve(x.data)
            }).catch((x) => {
                reject('failed')
            })
        })
    }
}

export const SeparatePhoneNums = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: apiURL + 'phonenumber/SeparatePhoneNums',
            }).then((x) => {
                resolve('done')
            }).catch((x) => {
                reject('failed')
            })
        })
    }
}
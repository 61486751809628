import React, { useEffect, useState} from "react";
import axios from "axios";
import { Button,  Label} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const apiURL = require('../../config.json').apiURL;

export const AlertPoll = () => {

  const [show, setShow] = useState(false);
    
  const [SOSCount, setSOSCount] = useState(0);

  const getAlert = async () => {
    axios.get(`${apiURL}defaulter/GetSOSList`).then((res)=> {
      if(res.data.length > JSON.parse(window.localStorage.getItem('sosCount')) ){
        let src = 'https://audio-previews.elements.envatousercontent.com/files/173153038/preview.mp3?response-content-disposition=attachment%3B+filename%3D%22NBUK26X-alarm.mp3%22';
        let audio = new Audio(src);
        audio.play();
        setShow(true)
        setSOSCount(res.data.length);
      }
      window.localStorage.setItem('sosCount', JSON.stringify(res.data.length))
    })
  };
  

  useEffect(() => {
    const timer = setInterval(getAlert, 180000);
    return () => clearInterval(timer);
  }, []);

  

  return (
          <Link id="sos_alert" color="red" 
            style={{display: show ? 'flex' : 'none', padding: '10px' ,backgroundColor: 'red', color: 'white', height: '100%',  alignItems: 'center',
                    justifyContent: 'center' }} 
            to='/sos'
          >
              Alert SOS Updated
          </Link>

    )
}
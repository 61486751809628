import React from "react";

import { Field, reduxForm } from "redux-form";

import {
  Form,
  Message,
  Modal,
  Header,
  Icon,
  Grid,
  Dropdown,
} from "semantic-ui-react";

import { CommonInput, CommonSelect, CommonMultiSelect } from "./commonInput";

let myField = (name, label, disabled, type = "text") => {
  return (
    <>
      <>
        <span>{label}</span>
        <div style={{ marginBottom: "3px" }} />
      </>
      <Field
        disabled={disabled}
        fluid
        name={name}
        component={CommonInput}
        placeholder={label}
        type={type}
      />
    </>
  );
};

let myGrid = (
  loading,
  reportToOptions,
  roleOptions,
  company,
  region,
  codes
) => {
  // console.log(codes)
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          {myField("Firstname", "First Name", loading)}
        </Grid.Column>
        <Grid.Column width={8}>
          {myField("Lastname", "Last Name", loading)}
        </Grid.Column>
        <Grid.Column width={8}>
          {myField("Email", "Email Address", loading, "email")}
        </Grid.Column>
        <Grid.Column width={8}>
          {myField("Contactnum", "Contact Number", loading)}
        </Grid.Column>
        <Grid.Column width={16}>{myField("Cnic", "CNIC", loading)}</Grid.Column>
        {/* <Grid.Column width={16}>
                    {myField('Cnic', 'CNIC1', loading)}
                </Grid.Column> */}
        <Grid.Column width={8}>
          <>
            <span>Activity Status</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="isactive"
            disabled={loading}
            fluid
            component={CommonSelect}
            options={[
              {
                value: true,
                text: "active",
              },
              {
                value: false,
                text: "inactive",
              },
            ]}
          />
        </Grid.Column>
        {/* <Grid.Column width={8}>
                    <>
                        <span>Report To</span>
                        <div style={{ marginBottom: '3px' }} />
                    </>
                    <Field
                        name='userid'
                        disabled={loading}
                        fluid
                        component={CommonSelect}
                        options={reportToOptions}
                    />
                </Grid.Column> */}
        <Grid.Column width={8}>
          <>
            <span>Superior(s)</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="superiorsIds"
            disabled={loading}
            fluid
            component={CommonMultiSelect}
            options={reportToOptions}
            selection
            multiple
            clearable={false}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <>
            <span>Company</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="companies"
            disabled={loading}
            fluid
            component={CommonMultiSelect}
            options={company}
            selection
            multiple
            clearable={false}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <>
            <span>Region</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="regions"
            disabled={loading}
            fluid
            component={CommonMultiSelect}
            options={region}
            selection
            multiple
            clearable={false}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <>
            <span>Codes</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="defaulterCodesIds"
            disabled={loading}
            fluid
            component={CommonMultiSelect}
            options={codes}
            selection
            multiple
            clearable={false}
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <>
            <span>Roles</span>
            <div style={{ marginBottom: "3px" }} />
          </>
          <Field
            name="roles"
            disabled={loading}
            fluid
            component={CommonMultiSelect}
            options={roleOptions}
            placeholder="No roles assigned"
            selection
            multiple
            clearable={false}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

let UserForm = (props) => {
  // console.log(props)
  const reportTo = props.reportToOptions.filter((x) => {
    return x.value !== props.uid;
  });
  return (
    <div>
      <Modal size={props.size} open={props.open} onClose={props.onCloseHandle}>
        <Header>
          <Icon name={props.header.icon} />
          {props.header.text}
        </Header>
        <Modal.Content>
          <Form
            id="userForm"
            error={
              props.Message !== null ? props.Message.type === "error" : false
            }
            success={
              props.Message !== null ? props.Message.type === "success" : false
            }
            onSubmit={props.handleSubmit}
            size="large"
          >
            {myGrid(
              props.loading,
              reportTo,
              props.roleOptions,
              props.companyOptions,
              props.regionOptions,
              props.codeOptions
            )}
            {props.Message !== null ? (
              <Message
                error={
                  props.Message !== null
                    ? props.Message.type === "error"
                    : false
                }
                success={
                  props.Message !== null
                    ? props.Message.type === "success"
                    : false
                }
                header={props.Message.header}
                content={props.Message.content}
              />
            ) : null}
          </Form>
        </Modal.Content>
        <Modal.Actions>{props.actionsComponent}</Modal.Actions>
      </Modal>
    </div>
  );
};

const valueCheck = (value, name, minLength = null) => {
  if (!value) {
    return `You must enter ${name}`;
  } else {
    if (minLength) {
      if (value.length < minLength) {
        return `${name} must be at least ${minLength} characters`;
      }
    }
  }
};

const validate = (formValues) => {
  const errors = {};
  errors.Name = valueCheck(formValues.Name, "company name", 3);
  errors.Contactperson = valueCheck(
    formValues.Contactperson,
    "contact person",
    3
  );
  // errors.Contactnum = valueCheck(formValues.Contactnum,'contact number',1)
  return errors;
};

UserForm = reduxForm({
  form: "user",
  validate,
  enableReinitialize: true,
})(UserForm);

export default UserForm;

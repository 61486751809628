import React, { useEffect, useState} from "react";
import axios from "axios";
import DataTable from '../components/dataTable';
import { Button, Table, Menu, Label} from 'semantic-ui-react';
import header from "../components/navigation/header";

const apiURL = require('../config.json').apiURL;

const SOS = () => {
    const [sos , setSos] =  useState([])

    useEffect(()=> {
        console.log('document.getElementById("sos_alert")', document.getElementById("sos_alert"));
        document.getElementById("sos_alert").style.display = 'none';

        axios.get(`${apiURL}defaulter/GetSOSList`).then((res)=> {
            console.log('in axios')
            const data = res.data.map((sosData)=> {
                const time = new Date(sosData.createdAt).toISOString().split('T')[0]+ " "+ new Date(sosData.createdAt).toISOString().split('T')[1].split('.')[0]
                return {
                    id: sosData.id,
                    userId:sosData.userId,
                    firstname: sosData.firstname,
                    lastname:sosData.lastname,
                    contactNum: sosData.contactNum,
                    status: sosData.status,
                    message: sosData.message,
                    location:<Menu.Item href={ `https://www.google.com/maps/search/?api=1&query=${sosData.latitude},${sosData.longitude}`} target='_blank'>
                                 view location
                             </Menu.Item>,
                    createdAt:   time
                }
            })
            setSos(data);
         })

    },[])

    const updateHandle = (data) => {
        const sosData = new FormData();
        sosData.append('Id', data.id)
        axios.put(`${apiURL}defaulter/UpdateSOSStatus`, sosData).then((res)=> {
            let soslist = sos;
            soslist =  soslist.filter((sos)=> {
            if(sos.id == res.data.id){
                sos.status = res.data.status 
            }
            return sos.status != 'Resolved';
           })
            setSos(soslist)
         })
    }
    
const headers =  [
    {
        name: 'User Id',
    },
    {
        name: 'First Name',
    },
    {
        name: 'Last Name',
    },
    {
        name: 'Phone Number',
    },
    {
        name: 'Status',
    },
    {
        name: 'Message',
    },
    {
        name: 'Location',
    },
    {
        name: 'CreatedAt',
    },
    {
        name: 'Action',
    }
]
    return(
        <div className="ui container">
            <h3>SOS List </h3>
            <Table fixed>
                <Table.Header>
                    <Table.Row>
                        {
                            headers.map((herder)=><Table.HeaderCell key={header.name}>{herder.name}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                {
                    sos.map((sosData, index)=> (
                        <Table.Row key={index}>
                            <Table.Cell>{sosData.userId}</Table.Cell>
                            <Table.Cell>{sosData.firstname}</Table.Cell>
                            <Table.Cell>{sosData.lastname}</Table.Cell>
                            <Table.Cell>{sosData.contactNum}</Table.Cell>
                            <Table.Cell>{sosData.status}</Table.Cell>
                            <Table.Cell>{sosData.message}</Table.Cell>
                            <Table.Cell>{sosData.location}</Table.Cell>
                            <Table.Cell>{sosData.createdAt}</Table.Cell>
                            <Table.Cell>
                            <Button size="mini" color="blue" onClick={()=> updateHandle(sosData)}>
                                {sosData.status}
                            </Button>
                        </Table.Cell>
                        </Table.Row>
                    ))
                    
                }   
                
                </Table.Body>
            </Table>
        </div>
    )
}

export default SOS;